@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Poppins", sans-serif;
  /* font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal; */
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}
.clearfix {
  clear: both;
  width: 100%;
  display: block !important;
}
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
ul li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
h1,
h2,
h3 {
  font-family: "Montserrat", sans-serif;
}
.heading-1 h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 700;
}
.home-section-1-bg {
  background-image: url(./images/home-section-1-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px 0px 50px 0px;
}

.home-section-1-bg h1 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.home-section-1-bg p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.wrapper {
  width: 96%;
  max-width: 1180px;
  margin: 0 auto;
}
.home-section-1-wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.home-section-1-1 {
  width: 46%;
  padding-left: 3%;

  box-sizing: border-box;
}
.home-section-1-2 {
  flex: 1;
  padding-left: 6%;
}
.box-1-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.box-1-1 {
  background-color: rgba(255, 255, 255, 0.5);
  width: 48%;
  padding: 10px;
  display: flex;
  border-radius: 10px;
}
.box-1-1-1 {
  width: 50px;
}
.box-1-1-1 img {
  width: 90%;
  height: auto;
}

.box-1-1-2 {
  flex: 1;
}

.box-1-1-2 h4 {
  font-size: 20px;
  color: #000;
}
.home-section-1-1 img {
  width: 100%;
}

.home-section-2-bg {
  background-color: #052d71;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 0px 50px 0px;
}

.home-section-2-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.home-section-2-wrap a {
  text-decoration: none;
  color: inherit;
}
.home-section-2-1 {
  width: 30%;
  text-align: center;
  /* background-image: url('data:image/svg+xml,<svg width="287" height="340" viewBox="0 0 287 340" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M287 17H127.674L0 340H170.602L287 17Z" fill="%2328477C"/><path d="M228 0H101.821L59 135H180.611L228 0Z" fill="%232B4B96"/></svg>'); */

  background-image: url('data:image/svg+xml,<svg width="236" height="233" viewBox="0 0 236 233" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M236 15H76.6742L0 233H169.5L236 15Z" fill="%2328477C"/><path d="M178 0H51.8209L9 135H130.611L178 0Z" fill="%232B4B96"/></svg>');

  background-repeat: no-repeat;
  /* background-size: 60% 75%; */
  background-size: 256px 223px;

  background-position: center center;
  transition: transform 1s ease;
  padding-bottom: 50px;
}

.home-section-2-1 img {
  height: 200px;
  width: auto;
}
.home-section-2-1:hover {
  /* background-image: url('data:image/svg+xml,<svg width="287" height="340" viewBox="0 0 287 340" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M287 17H127.674L0 340H170.602L287 17Z" fill="%23a0bec7"/><path d="M228 0H101.821L59 135H180.611L228 0Z" fill="%23ffffff"/></svg>'); */

  background-image: url('data:image/svg+xml,<svg width="236" height="233" viewBox="0 0 236 233" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M236 15H76.6742L0 233H169.5L236 15Z" fill="%2399BFC8"/><path d="M178 0H51.8209L9 135H130.611L178 0Z" fill="white"/></svg>');

  background-repeat: no-repeat;
  /* background-size: 60% 75%; */
  transform: scale(1.05);
}
.home-section-2-dec {
  padding: 40px 0px 0px 0px;
  color: #fff;
  text-align: center;
  width: 96%;
  margin: 0 auto;
  max-width: 280px;
}
.home-section-2-dec h4 {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}
.home-section-2-dec h6 {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  max-width: 220px;
  margin: 0 auto;
  height: 80px;
}
.heading-1 {
  text-align: center;
  color: #fff;
  margin: 10px 0px 50px 0px;
}

.heading-1 h4 span {
  background-color: #052d71;
  padding: 10px;
  position: relative;
  color: #f17e00;
  font-size: 20px;
}
.heading-1 h4 span:after {
  position: absolute;
  background-image: linear-gradient(90deg, #f17e00 0%, #052d71 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 150px;
  height: 6px;
  left: 110%;
  content: "";

  top: 40%;
}
.heading-1 h4 span:before {
  position: absolute;
  background-image: linear-gradient(-90deg, #f17e00 0%, #052d71 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 150px;
  height: 6px;
  right: 110%;
  content: "";
  top: 40%;
}

.home-section-3-bg {
  background-image: url(./images/map-new-1.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding: 75px 0px;
  background-position: center;
}
.home-section-3-wrap {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  max-width: 1280px;
}
.home-section-3-1 {
  width: 96%;
  max-width: 300px;
  position: relative;
}
.home-section-headoffice {
  position: absolute;
  bottom: 10%;
  left: 0px;
  width: 100%;
  color: #052d71;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.home-section-3-2 {
  width: 300px;
  text-align: right;
}

.box-2-wrap {
  display: flex;
  width: 100%;
  /* justify-content: end; */
  justify-content: space-between;
  flex-wrap: wrap;
}
.box-2-1 {
  background-color: #f1f1f1;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 35px;
  stroke-width: 4.426px;

  filter: drop-shadow(-17.702px 0px 66.384px rgba(0, 0, 0, 0.21));
  width: 45%;

  height: auto;
  outline: 5px solid #fff;
}

.box-2-1-1 {
  width: 100%;
}
.box-2-1-1 svg {
  width: 40px;
  height: auto;
  margin: 4px 0px;
}
.box-2-1-2 {
  flex: 1;
}
.box-2-1-1 {
  width: 100%;
  text-align: center;
}
.box-2-1-2 {
  width: 100%;
  text-align: center;
}
.box-2-1-2 h4 {
  font-size: 17px;
  margin: 8px 0px;
  padding: 0px;
  font-weight: 600;
  color: #000;
}
.box-2-1-2 p {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 0px;
  margin: 0px;
}

.heading-2 h2 {
  font-size: 30px;
  margin: 0px;
  padding: 0px;
  font-weight: 700;
  line-height: 37px;
}
.heading-2 h2 b {
  color: #052d71;

  font-size: 32px;
  font-weight: 700;
}
.heading-2 h6 {
  /* color: #052d71;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 0px 0px 0px;
  margin: 0px; */

  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: 3px solid #ffffff;
  border-radius: 3px;
  background: #052c71;
  padding: 10px 20px;
  margin: 10px 0px;
  display: inline-block;
  -webkit-box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.72);
  box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.72);
  margin-left: -5px;
}
.heading-2 h4 {
  text-align: left;
  color: #fff;
  margin: 10px 0px 20px 0px;
  padding: 0px;
}
.heading-2 h4 span {
  padding: 0px;
  position: relative;
  color: #f17e00;
  font-size: 20px;
}
.heading-2 h4 span:after {
  position: absolute;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="60" height="4" viewBox="0 0 60 4" fill="none"><path d="M0 2H60" stroke="url(%23paint0_linear_261_779)" stroke-width="3"/><defs><linearGradient id="paint0_linear_261_779" x1="16.9149" y1="0.499999" x2="76.9149" y2="0.499999" gradientUnits="userSpaceOnUse"><stop stop-color="%23F17E00"/><stop offset="1" stop-color="white"/></linearGradient></defs></svg>');
  background-size: 80%;
  background-repeat: no-repeat;
  width: 100px;
  height: 5px;
  left: 110%;
  content: "";

  top: 40%;
}
.heading-2 h3 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.heading-2-small h4 span {
  padding: 0px;
  position: relative;
  color: #f17e00;
}
.heading-2-small h4 span:after {
  position: absolute;
  background: linear-gradient(90deg, #f17e00 0%, rgba(255, 255, 255, 0) 100%);
  background-size: 80%;
  background-repeat: no-repeat;
  width: 150px;
  height: 8px;
  left: 110%;
  content: "";

  top: 40%;
}

.get-in-touch-bg {
  background-image: url(./images/get-in-touch-bg.png);
  background-size: cover;
  padding: 50px 0px;
}
.get-in-touch-form {
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 14.589px 0px rgba(0, 0, 0, 0.32);
  width: 96%;
  margin: 0 auto;
  max-width: 600px;
}
.blue-color {
  color: #052d71;
}
.text-field-1 {
  border: 0px;
  border-bottom: 1px solid #ccc;
  padding: 10px 3px;
  font-size: 16px;
  color: #000;
  width: 100%;
  outline: none;
  margin-bottom: 10px;
}

.text-area-1 {
  border: 0px;
  border-bottom: 1px solid #ccc;
  padding: 10px 5px;
  font-size: 20px;
  color: #000;
  width: 100%;
  outline: none;
  margin-bottom: 10px;
  min-height: 130px;
  resize: none;
}
.button-1 {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(%23clip0_443_346)"><path d="M13.5933 18.44L20 12L13.5933 5.56001C13.5355 5.48403 13.462 5.42137 13.3778 5.37627C13.2936 5.33116 13.2007 5.30468 13.1054 5.29859C13.0101 5.29251 12.9146 5.30698 12.8253 5.34102C12.7361 5.37506 12.6552 5.42787 12.5882 5.49588C12.5211 5.5639 12.4695 5.64552 12.4367 5.73523C12.404 5.82494 12.3909 5.92064 12.3983 6.01586C12.4057 6.11107 12.4336 6.20357 12.4799 6.28711C12.5262 6.37064 12.5899 6.44324 12.6667 6.50001L17.46 11.3333L4.70667 11.3333C4.52986 11.3333 4.36029 11.4036 4.23526 11.5286C4.11024 11.6536 4.04 11.8232 4.04 12C4.04 12.1768 4.11024 12.3464 4.23526 12.4714C4.36029 12.5964 4.52986 12.6667 4.70667 12.6667L17.46 12.6667L12.6667 17.5C12.542 17.6255 12.4723 17.7955 12.473 17.9724C12.4736 18.1493 12.5445 18.3187 12.67 18.4433C12.7955 18.568 12.9654 18.6377 13.1424 18.6371C13.3193 18.6364 13.4887 18.5655 13.6133 18.44H13.5933Z" fill="black"/></g><defs><clipPath id="clip0_443_346"><rect width="24" height="24" fill="white" transform="matrix(0 1 -1 0 24 0)"/></clipPath></defs></svg>');
  background-repeat: no-repeat;
  background-position: center right 14px;
  padding: 8px 46px 8px 20px;
  background-color: #fff;

  border: 1px solid #ccc;
  -webkit-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  transition: 0.5s;
  text-decoration: none;
  color: #000;
  font-size: 16px;
}

.filter-button-1 {
  background-color: #fff;
  padding: 10px 5px !important;

  border: 1px solid #ccc !important;
  -webkit-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  transition: 0.5s;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  text-align: center !important;
}

.button-1:hover {
  background-image: url('data:image/svg+xml,<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.59333 13.44L16 7.00001L9.59333 0.560014C9.53547 0.484032 9.46196 0.421368 9.37778 0.376266C9.29359 0.331163 9.2007 0.304675 9.10539 0.298594C9.01008 0.292513 8.91458 0.306981 8.82534 0.341019C8.73611 0.375057 8.65523 0.42787 8.58818 0.495883C8.52114 0.563895 8.46948 0.64552 8.43672 0.73523C8.40396 0.82494 8.39086 0.920642 8.3983 1.01586C8.40575 1.11107 8.43356 1.20357 8.47986 1.28711C8.52616 1.37064 8.58987 1.44324 8.66667 1.50001L13.46 6.33335L0.706667 6.33335C0.529856 6.33335 0.360288 6.40358 0.235263 6.52861C0.110239 6.65363 0.0400006 6.8232 0.0400006 7.00001C0.0400006 7.17682 0.110239 7.34639 0.235263 7.47142C0.360288 7.59644 0.529856 7.66668 0.706667 7.66668L13.46 7.66668L8.66667 12.5C8.54202 12.6255 8.47234 12.7955 8.47296 12.9724C8.47359 13.1493 8.54446 13.3187 8.67 13.4433C8.79554 13.568 8.96545 13.6377 9.14236 13.6371C9.31927 13.6364 9.48868 13.5655 9.61333 13.44L9.59333 13.44Z" fill="white"/></svg>');
  background-repeat: no-repeat;
  background-position: center right 14px;
  padding: 8px 46px 8px 20px;
  background-color: #7e8cb1;
  color: #fff;

  border: 0px solid #ccc;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  scale: 0.96;
}

.button-2a:hover {
  background-color: #7e8cb1;
  color: #fff;

  border: 0px solid #ccc;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  scale: 0.96;
}

.footer-bg {
  padding: 15px 0px;
  text-align: center;
  background-color: #000000;
  color: #fff;
}
.carousel-caption {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.text-center-1 {
  text-align: center !important;
  padding: 0px 0px 30px 0px;
  font-size: 18px;

  margin: 0 auto;
  width: 96%;
  max-width: 600px;
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
}
.carousel-caption {
  height: 70%;
  bottom: 0px;
  text-align: left;
  width: 100%;
  left: 0% !important;
  right: 0% !important;
}
.carousel-text {
  text-align: left;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  height: 98%;
  /* padding-bottom: 80px; */
}
.carousel-text-1 {
  width: 60%;
  display: flex;

  flex-wrap: wrap;
}

.carousel-text-1-1 {
  align-self: flex-start;
  width: 100%;
  text-align: left;
}
.carousel-text-1-2 {
  align-self: flex-end;
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
}
.carousel-text-2 {
  width: 40%;

  border-radius: 10px;
  background: rgba(0, 0, 0, 0.36);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.71);
  -moz-box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.71);
  box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.71);
}
.carousel-text-2 img {
  width: 100% !important;
  height: auto !important;
}
.brands-bg {
  background: #fff;
  box-shadow: 0px 0px 13.466px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.relative {
  position: relative !important;
}
.brands-bg {
  /* position: relative; */
  top: -80px;
  padding: 0px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 56px;
  margin-top: -95px;
}
.brand-img-1 {
  white-space: nowrap;
  font-weight: 500;
  font-size: 18px;
  padding: 20px 3%;
}
.brand-img-1 img {
  vertical-align: middle;
  margin-right: 8px;
  height: 50px;
  width: auto;
}
/* 
.slideshow {
  position: relative;
  z-index: 1;
  height: 100%;
  max-width: 700px;
  margin: 50px auto;
}
.slideshow * {
  outline: none;
}
.slideshow .slider {
  box-shadow: 0 20px 50px -25px black;
}
.slideshow .slider-track {
  -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
}
.slideshow .item {
  height: 100%;
  position: relative;
  z-index: 1;
}
.slideshow .item img {
  width: 100%;
  -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}
.slideshow .item.slick-active img {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation: cssAnimation 8s 1 ease-in-out forwards;
  animation: cssAnimation 8s 1 ease-in-out forwards;
}

@keyframes cssAnimation {
  from {
    -webkit-transform: scale(1) translate(0px);
  }
  to {
    -webkit-transform: scale(1.3) translate(0px);
  }
}
@-webkit-keyframes cssAnimation {
  from {
    -webkit-transform: scale(1) translate(0px);
  }
  to {
    -webkit-transform: scale(1.3) translate(0px);
  }
} */

.banner-heading-1 h4 {
  text-align: left;
  color: #fff;
  margin: 10px 0px 20px 0px;
  padding: 0px;
}
.banner-heading-1 h1 span {
  padding: 0px;
  position: relative;
  color: #f17e00;
  text-transform: uppercase;
  font-size: 24px;
  font-style: normal;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 90%);
}
.banner-heading-1 h4 span {
  padding: 0px;
  position: relative;
  color: #f17e00;
  text-transform: uppercase;
  font-size: 24px;
  font-style: normal;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 90%);
}

.banner-heading-1 h1 span:after {
  position: absolute;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0.2) 0%,
    #f17e00 100%
  );
  background-size: 80%;
  background-repeat: no-repeat;
  width: 80px;
  height: 5px;
  left: 110%;
  content: "";
  top: 40%;
}

.banner-heading-1 h4 span:after {
  position: absolute;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0.2) 0%,
    #f17e00 100%
  );
  background-size: 80%;
  background-repeat: no-repeat;
  width: 80px;
  height: 5px;
  left: 110%;
  content: "";
  top: 40%;
}

.banner-heading-1 {
  -webkit-animation: slide-down 1s forwards;
  animation: slide-down 1s forwards;
}
.carousel-text-1 h3 {
  -webkit-animation: slide-down 1.2s forwards;
  animation: slide-down 1.2s forwards;
}
.filter-text-box {
  -webkit-animation: slide-down 1.6s forwards;
  animation: slide-down 1.6s forwards;
}
.carousel-text-1-1 a.button-1 {
  -webkit-animation: slide-down 2s forwards;
  animation: slide-down 2s forwards;
}

.carousel-text-1 h3 {
  color: #ffffff;
  width: 90%;
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

@-webkit-keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.inner-banner {
  background-image: url(./images/inner-banner.png);
  background-size: cover;
  padding: 80px 0px;
}

.filter-banner {
  background-image: url(./images/inner-banner.png);
  background-size: cover;
  padding: 10px 0px;
  min-height: 50px;
}

.career-banner-1 {
  background-image: url(./images/career-banner-1.png) !important;
}
.inner-map {
  padding: 0px 0px;
}
.inner-map iframe {
  width: 100%;
  height: 300px;
}
.inner-banner h1 {
  color: #fff;

  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 50px;
  max-width: 520px;
}
.inner-banner h6 {
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 520px;
  margin: 16px 0px;
}

.about-section-1-bg {
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0px 60px 0px;
}

.about-section-1-bg h1 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.about-section-1-bg p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.about-section-1-wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.about-section-1-1 {
  width: 40%;

  box-sizing: border-box;
  position: relative;
  height: fit-content;
}
.about-section-1-1 img {
  position: relative;
  z-index: 1;
}
.about-section-1-1::after {
  border: 1px solid #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  left: -20px;
  top: 20px;
  z-index: -0;
}
.about-section-1-1 img {
  width: 100%;
  height: auto;

  box-sizing: border-box;
}

.about-section-1-2 {
  margin: auto;
  flex: 1;
  padding-left: 3%;
}

.about-section-2 {
  background: #dceaf7;
  display: flex;
}

.about-section-2-1 {
  width: 50%;
}

.about-section-2-txt {
  width: 100%;
  max-width: 580px;
  display: flex;
  float: right;
}
.about-section-2-txt-1 {
  padding: 30px 20px;
  flex: 1;
}
.about-section-2-txt-2 {
  width: 200px;
}
.about-section-2-txt-2 img {
  width: 100%;
  height: auto;
  filter: drop-shadow(-8px 0px 0rem #fff);
}

.about-section-2-2 {
  width: 50%;
}
.about-section-2-2,
.about-section-2-1 {
  transition: 1s;
  overflow: hidden;
}
.about-section-2-2:hover,
.about-section-2-1:hover {
  background-color: #052d71;
  background-size: 60% 75%;

  color: #fff;
}

.about-section-2-2 img,
.about-section-2-1 img {
  transition: 1s;
}
.about-section-2-2:hover img,
.about-section-2-1:hover img {
  transform: scale(1.1);
  filter: drop-shadow(-0px 0px 0rem #fff);
}

.about-section-2-2 .about-section-2-2-txt-1 h2 {
  transition: 1s;
  margin-right: 0%;
}
.about-section-2-2:hover .about-section-2-2-txt-1 h2 {
  margin-right: 10%;
  transition: 1s;
}

.about-section-2-2 h2,
.about-section-2-1 h2 {
  color: #041636;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 150% */
  text-transform: capitalize;
}

.about-section-2-2:hover h2,
.about-section-2-1:hover h2 {
  color: #fff;
}
.about-section-2-2-txt {
  width: 100%;
  max-width: 580px;
  display: flex;
  float: left;
}
.about-section-2-2-txt-1 {
  padding: 30px 20px;
  flex: 1;
  text-align: right;
}

.about-section-2-2-txt-2 {
  width: 200px;
}
.about-section-2-2-txt-2 img {
  width: 100%;
  height: auto;
  filter: drop-shadow(8px 0px 0rem #fff);
}

.about-section-3-wrap {
  display: flex;
  width: 96%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px auto;
  max-width: 1170px;
}
.about-section-3-1 {
  width: 32%;
  margin: 20px 0px;
  border-radius: 5px;
  border: 9px solid #edf6ff;
  background: #fff;
  padding: 10px 20px;
  transition: 1s;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}
.about-section-3-1 a {
  text-decoration: none;
  display: block;
}

.about-section-3-1:hover {
  background-image: url(./images/Chairman-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  scale: 1.03;
}
.about-section-3-1 svg {
  transition: 1s;
}

.about-section-3-1:hover .icon-co svg {
  background-color: #fff;
  padding: 2px 5px;

  scale: 0.9;
  border-radius: 5px;
}

.about-section-3-1:nth-child(3):hover {
  background-image: url(./images/ehs_policy.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  scale: 1.05;
}
.about-section-3-1:nth-child(2):hover {
  background-image: url(./images/Awards.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  scale: 1.05;
}

.about-section-3-1:hover h4,
.about-section-3-1:hover p {
  color: #fff;
}

.about-section-3-wrap h4 {
  color: #041636;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 183.963% */
  text-transform: capitalize;
  margin: 10px 0px 10px 0px;
  line-height: 24px;
}
.about-section-3-wrap p {
  color: rgba(79, 79, 79, 0.97);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
  padding: 0px;
}

.about-section-3-wrap svg {
  width: 60px;
  height: auto;
}

.contactbtn1 {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(%23clip0_443_346)"><path d="M13.5933 18.44L20 12L13.5933 5.56001C13.5355 5.48403 13.462 5.42137 13.3778 5.37627C13.2936 5.33116 13.2007 5.30468 13.1054 5.29859C13.0101 5.29251 12.9146 5.30698 12.8253 5.34102C12.7361 5.37506 12.6552 5.42787 12.5882 5.49588C12.5211 5.5639 12.4695 5.64552 12.4367 5.73523C12.404 5.82494 12.3909 5.92064 12.3983 6.01586C12.4057 6.11107 12.4336 6.20357 12.4799 6.28711C12.5262 6.37064 12.5899 6.44324 12.6667 6.50001L17.46 11.3333L4.70667 11.3333C4.52986 11.3333 4.36029 11.4036 4.23526 11.5286C4.11024 11.6536 4.04 11.8232 4.04 12C4.04 12.1768 4.11024 12.3464 4.23526 12.4714C4.36029 12.5964 4.52986 12.6667 4.70667 12.6667L17.46 12.6667L12.6667 17.5C12.542 17.6255 12.4723 17.7955 12.473 17.9724C12.4736 18.1493 12.5445 18.3187 12.67 18.4433C12.7955 18.568 12.9654 18.6377 13.1424 18.6371C13.3193 18.6364 13.4887 18.5655 13.6133 18.44H13.5933Z" fill="black"/></g><defs><clipPath id="clip0_443_346"><rect width="24" height="24" fill="white" transform="matrix(0 1 -1 0 24 0)"/></clipPath></defs></svg>');
  background-repeat: no-repeat;
  background-position: center right 14px;
  padding: 6px 40px 6px 10px;
  background-color: #fff;

  border: 1px solid #ccc;
  -webkit-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  height: fit-content;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 14px;
}
.contactbtn1 a {
  color: #000;
  text-decoration: none;
}

.product-inner-sec1-bg {
  padding: 25px 0px;
  background-color: #f7fbff;
}
.product-inner-sec1-wrap {
  display: flex;
  width: 100%;
}
.product-inner-sec1-1 {
  width: 260px;

  height: fit-content;
}

.product-inner-sec1-1-bg {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 14px;
  height: fit-content;
  margin-top: 28px;
}

.product-inner-sec1-1 button {
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px 8px;
  border: 0px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 6;
  font-style: normal;
  font-weight: 500;
}
.product-inner-sec1-2 {
  flex: 1;
  padding-left: 5%;
}
.product-inner-sec1-2 h1 {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.product-inner-sec1-2 h2 {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.product-inner-sec1-2 h3 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Poppins", sans-serif;
}
.view-product-1 a {
  color: #000;

  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.view-product-1 img {
  height: 190px;
  width: auto;
  max-width: 99%;
}
.view-product {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #f3f3f3;
  margin-bottom: 20px;
  border-radius: 5px;
}
.view-product-1 {
  width: 31%;
  text-align: center;
  margin-bottom: 25px;
  padding: 10px;
  box-sizing: border-box;
}

.view-product-1:hover {
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

.view-product-1 h6 {
  font-weight: 300;
  font-size: 13px;
  color: #454545;
  font-style: italic;
  margin: 0px;
  padding: 0px;
}
.Certifications-sec2-bg {
  padding: 30px 0px;
}

.our-comitments-bg-2 {
  background-image: url(./images/comitments-1.png),
    url(./images/comitments-2.png);
  padding: 20px 0px;
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: left center, right center;
}
.full-1 {
  text-align: right;
  margin: 20px 0px -30px 0px;
}

.comitments-wrap {
  width: 96%;
  max-width: 600px;
  margin: 0 auto;
}

.comitments-wrap ul {
  width: fit-content;
  max-width: 500px;
  list-style: none;
  margin: 20px auto;

  padding: 0px;
}
.comitments-wrap ul li {
  margin: 5px 0px;
  background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8.5" fill="white" stroke="%23052D71"/><g clip-path="url(%23clip0_612_446)"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.4866 5.72726L6.95363 13.2603L2.69336 8.99999L3.27181 8.42153L6.95363 12.1033L13.9082 5.1488L14.4866 5.72726Z" fill="%23052D71"/></g><defs><clipPath id="clip0_612_446"><rect width="12.2727" height="12.2727" fill="white" transform="translate(2.4541 3.27271)"/></clipPath></defs></svg>');
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: left top 6px;
  padding: 4px 0px 4px 30px;
  font-size: 16px;
  text-align: left;
}
.list-h-1 {
  margin: 5px 0px;
  background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8.5" fill="white" stroke="%23052D71"/><g clip-path="url(%23clip0_612_446)"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.4866 5.72726L6.95363 13.2603L2.69336 8.99999L3.27181 8.42153L6.95363 12.1033L13.9082 5.1488L14.4866 5.72726Z" fill="%23052D71"/></g><defs><clipPath id="clip0_612_446"><rect width="12.2727" height="12.2727" fill="white" transform="translate(2.4541 3.27271)"/></clipPath></defs></svg>');
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: left top 6px;
  padding: 4px 0px 4px 30px;
  color: #052d71;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}
.comitments-wrap h3 {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
}
.comitments-wrap h4 {
  color: #052d71;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.comitments-wrap {
  text-align: center;
}
.line-1 {
  height: 1px;
  background-color: #000;
  width: 100%;
  margin: 20px 0px;
}
.m-b-1 {
  margin-bottom: 20px !important;
}
.m-t-1 {
  margin-top: 40px !important;
}
.m-t-2 {
  margin-top: 30px !important;
}
.QualityPromise-section-3 {
  background-color: #ecf5ff;
  padding: 30px 0px;
}
.QualityPromise-section-3-wrap {
  display: flex;
  width: 96%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px auto;
  max-width: 800px;
  text-align: center;
}
.QualityPromise-section-3-1 {
  width: 48%;
  margin: 20px 0px;
  border-radius: 5px;
  border: 9px solid #edf6ff;
  background: #fff;
  padding: 30px 20px;
  text-align: center;
}
.QualityPromise-section-3-wrap h4 {
  color: rgba(30, 52, 136, 0.97);

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 183.963% */
  text-transform: capitalize;
  margin: 10px 0px 10px 0px;
  line-height: 24px;
}

.QualityPromise-section-3-wrap svg {
  width: 60px;
  height: auto;
}
.QualityPromise-bg-2 {
  background-image: url(./images/QualityPromise-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0px 30px 0px;
  overflow: hidden;
}
.heading-2-small h2 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.text-style-1 {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
}
.inside-bg-2 {
  padding: 30px 0px;
  background-image: none;
}
.comitments-wrap-2 {
  width: 96%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0px 0px 0px;
}

.em-section-1-wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 50px 0px;
}
.em-section-1-1 {
  width: 38%;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  margin: auto;
}

.em-section-1-1 img {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.em-section-1-2 {
  margin: auto;
  flex: 1;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  padding: 30px 30px;
}
.em-section-1-wrap h2 {
  color: rgba(0, 0, 0, 0.97);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  text-transform: capitalize;
  padding: 0px;
  margin: 0px 0px 20px 0px;
}
.em-section-1-wrap h4 {
  color: #052d71;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.list-1 ol {
  margin: 0px 0px 0px 20px;
  padding: 0px;
  list-style: none;
}
.list-1 ol li {
  margin: 0px 0px 20px 0px;
  padding: 0px;
  list-style: auto;
}
.em-section-1-wrap p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.product-dis-banner {
  background-color: #22469d;
  position: relative;
  background-image: url(./images/products-banner-bg.png);
  background-repeat: repeat-x;
  background-position: left center;
  background-size: contain;
}
.product-dis-banner::after {
  position: absolute;
  content: "123";
  width: 50%;
  background-color: #e3e3e3;
  right: 0px;
  height: 100%;
  top: 0px;
}
.product-dis-content {
  width: 80%;
  background-color: #e3e3e3;
  padding: 40px 0px;
  position: relative;
  z-index: 1;
  right: 0px;
  margin-left: 20%;
}
.product-dis-wrap {
  display: flex;
  width: 100%;
}
.product-dis-1 {
  width: 40%;
  margin-left: -18%;
  padding-right: 2%;
}
.product-dis-1 img {
  width: auto;
  max-width: 96%;
  height: auto;
  /* box-shadow: inset 1px 1px 10px #0000003b; */
  border-radius: 5px;
  border: 1px solid #47beff;
}
.product-dis-2 {
  flex: 1;
  margin: auto;
}
.product-dis-2 h1 {
  /* font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;  
  text-transform: capitalize;
  margin: 10px 0px 10px 0px; */
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: capitalize;
  margin: 10px 0px 10px 0px;
  width: 90%;
}
.product-dis-2 h6 {
  color: rgba(0, 0, 0, 0.97);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin: 20px 0px 20px 0px;
  line-height: 24px;
  max-width: 100%;
}

.product-i-sec1-bg {
  background-image: url(./images/product-i-sec1-1.png),
    url(./images/product-i-sec1-2.png);
  padding: 40px 0px;
  background-repeat: no-repeat;
  background-size: 25%;
  background-position: left top, right bottom;
}
.product-i-sec1-wrap {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}
.product-i-sec1-1 {
  /* max-width: 45%; */
  width: 45%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #dceaf7;
  border-radius: 10px;
  margin-bottom: 30px;
}

.product-i-sec1-1:last-child {
  max-width: 45%;
  width: fit-content !important;
  min-width: 25%;
}
.product-i-sec1-1 ul {
  width: 100%;
  /* max-width: 400px; */
  list-style: none;
  margin: 6px 0px 20px 0px;

  padding: 0px;
}
.product-i-sec1-1:last-child ul {
  width: fit-content !important;
}
.product-i-sec1-1 ul li {
  margin: 5px 0px;
  background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6.5" stroke="black"/><g clip-path="url(%23clip0_631_447)"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.2682 4.45455L5.40925 10.3135L2.0957 7L2.54561 6.55009L5.40925 9.41373L10.8183 4.00464L11.2682 4.45455Z" fill="black"/></g><defs><clipPath id="clip0_631_447"><rect width="9.54545" height="9.54545" fill="white" transform="translate(1.90918 2.54541)"/></clipPath></defs></svg>');
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: left top 6px;
  padding: 4px 0px 4px 30px;
  font-size: 16px;
  text-align: left;
}

.product-i-sec1-1 h3 {
  color: #000;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
}

.button-2 {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(%23clip0_443_346)"><path d="M13.5933 18.44L20 12L13.5933 5.56001C13.5355 5.48403 13.462 5.42137 13.3778 5.37627C13.2936 5.33116 13.2007 5.30468 13.1054 5.29859C13.0101 5.29251 12.9146 5.30698 12.8253 5.34102C12.7361 5.37506 12.6552 5.42787 12.5882 5.49588C12.5211 5.5639 12.4695 5.64552 12.4367 5.73523C12.404 5.82494 12.3909 5.92064 12.3983 6.01586C12.4057 6.11107 12.4336 6.20357 12.4799 6.28711C12.5262 6.37064 12.5899 6.44324 12.6667 6.50001L17.46 11.3333L4.70667 11.3333C4.52986 11.3333 4.36029 11.4036 4.23526 11.5286C4.11024 11.6536 4.04 11.8232 4.04 12C4.04 12.1768 4.11024 12.3464 4.23526 12.4714C4.36029 12.5964 4.52986 12.6667 4.70667 12.6667L17.46 12.6667L12.6667 17.5C12.542 17.6255 12.4723 17.7955 12.473 17.9724C12.4736 18.1493 12.5445 18.3187 12.67 18.4433C12.7955 18.568 12.9654 18.6377 13.1424 18.6371C13.3193 18.6364 13.4887 18.5655 13.6133 18.44H13.5933Z" fill="black"/></g><defs><clipPath id="clip0_443_346"><rect width="24" height="24" fill="white" transform="matrix(0 1 -1 0 24 0)"/></clipPath></defs></svg>');
  background-repeat: no-repeat;
  background-position: center right 14px;
  padding: 8px 46px 8px 20px;
  background-color: #fff;

  border: 1px solid #ccc;
  -webkit-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  text-decoration: none;
  color: #000;
  font-size: 16px;
}

.button-2a {
  padding: 4px 8px 4px 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  -webkit-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  text-decoration: none;
  color: #000;
  margin: 0px 15px 0px 0px;
}
.button-2b {
  padding: 8px 8px 8px 8px;
  background-color: #fff;
  border: 0px solid #ccc;
  text-decoration: none;
  color: #000;
  margin: 0px 15px 0px 0px;
}

.button-3 {
  background-image: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.8 12H2V13H2.8C3.1 13 3.3 12.8 3.3 12.5C3.3 12.2 3.1 12 2.8 12ZM8 0H0V16H13V5L8 0ZM4.2 12.6C4.2 13.4 3.6 14 2.8 14H2V15H1V11H2.8C3.6 11 4.2 11.6 4.2 12.4V12.6ZM8.3 13.1C8.3 14.1 7.5 15 6.4 15H5V11H6.4C7.4 11 8.3 11.8 8.3 12.9V13.1ZM12 12H10V13H11.5V14H10V15H9V11H12V12ZM12 10H1V1H8V5H12V10ZM6.4 12H6V14H6.4C7 14 7.4 13.6 7.4 13C7.4 12.4 6.9 12 6.4 12Z" fill="black"/></svg>');
  background-repeat: no-repeat;
  background-position: center right 14px;
  padding: 8px 46px 8px 20px;
  background-color: #fff;

  border: 1px solid #ccc;
  -webkit-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 4px 7px 0px 0px rgba(126, 140, 177, 1);
  text-decoration: none;
  color: #000;
  transition: 1s;
}
a.button-3 {
  display: inline-block;
  padding: 10px 46px 10px 20px;
  margin: 0px 10px 0px 0px;
}
.button-3:hover {
  background-color: #7e8cb1;
  color: #fff;
  background-image: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.8 12H2V13H2.8C3.1 13 3.3 12.8 3.3 12.5C3.3 12.2 3.1 12 2.8 12ZM8 0H0V16H13V5L8 0ZM4.2 12.6C4.2 13.4 3.6 14 2.8 14H2V15H1V11H2.8C3.6 11 4.2 11.6 4.2 12.4V12.6ZM8.3 13.1C8.3 14.1 7.5 15 6.4 15H5V11H6.4C7.4 11 8.3 11.8 8.3 12.9V13.1ZM12 12H10V13H11.5V14H10V15H9V11H12V12ZM12 10H1V1H8V5H12V10ZM6.4 12H6V14H6.4C7 14 7.4 13.6 7.4 13C7.4 12.4 6.9 12 6.4 12Z" fill="white"/></svg>');
  background-position: center right 14px;
  padding: 8px 46px 8px 20px;
  background-color: #7e8cb1;
  color: #fff;

  border: 0px solid #ccc;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  scale: 0.96;
}
.button-2 {
  transition: 1s;
}
.button-2:hover {
  background-color: #7e8cb1;
  color: #fff;
  background-image: url('data:image/svg+xml,<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.59333 13.44L16 7.00001L9.59333 0.560014C9.53547 0.484032 9.46196 0.421368 9.37778 0.376266C9.29359 0.331163 9.2007 0.304675 9.10539 0.298594C9.01008 0.292513 8.91458 0.306981 8.82534 0.341019C8.73611 0.375057 8.65523 0.42787 8.58818 0.495883C8.52114 0.563895 8.46948 0.64552 8.43672 0.73523C8.40396 0.82494 8.39086 0.920642 8.3983 1.01586C8.40575 1.11107 8.43356 1.20357 8.47986 1.28711C8.52616 1.37064 8.58987 1.44324 8.66667 1.50001L13.46 6.33335L0.706667 6.33335C0.529857 6.33335 0.360288 6.40358 0.235264 6.52861C0.110239 6.65363 0.0400009 6.8232 0.0400009 7.00001C0.0400009 7.17682 0.110239 7.34639 0.235264 7.47142C0.360288 7.59644 0.529857 7.66668 0.706667 7.66668L13.46 7.66668L8.66667 12.5C8.54202 12.6255 8.47234 12.7955 8.47296 12.9724C8.47359 13.1493 8.54446 13.3187 8.67 13.4433C8.79554 13.568 8.96545 13.6377 9.14236 13.6371C9.31927 13.6364 9.48868 13.5655 9.61333 13.44L9.59333 13.44Z" fill="white"/></svg>');

  border: 0px solid #ccc;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  -moz-box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  box-shadow: 0px 0px 0px 0px rgba(126, 140, 177, 1);
  scale: 0.96;
}
.m-l-1 {
  margin-left: 20px;
}

.certifications-wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.certifications-1 {
  width: 23%;
  flex-wrap: wrap;
  background-image: url(./images/Certifications-bg.png);
  background-repeat: repeat;

  background-repeat: repeat-y;
  background-color: #052d71;
  border-radius: 6px;
  outline: 1px solid #ccc;
  margin: 15px 1%;
}
.certifications-text-wrap {
  display: flex;
  width: 80%;
  background-color: #fff;
  padding: 20px;
  margin-left: 25%;
  height: 100%;
}
.certifications-text-1 {
  position: relative;
}
.certifications-text-2 {
  flex: 1;
  padding-left: 20px;
  margin: auto;
}
.certifications-text-2 h4 {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0px 0px 20px 0px;
  padding: 0px;
}
.certifications-text-1 img {
  margin-left: -60px;
  width: 80px;
  height: auto;
}
.AwardsAchievement-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.AwardsAchievement-1 {
  width: 48%;
}
.AwardsAchievement-box {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 9.616px 0px rgba(0, 0, 0, 0.35);
  padding: 10px;
}
.AwardsAchievement-1 img {
  width: 100%;
}
.AwardsAchievement-1 p {
  width: 90%;
  text-align: center;
  margin: 10px auto;
  padding: 0px;
}

.AwardsAchievement2-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;

  flex-wrap: wrap;
}
.AwardsAchievement-2 {
  width: 48%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 9.616px 0px rgba(0, 0, 0, 0.35);
  padding: 10px;
  margin-bottom: 20px;
}

.AwardsAchievement-2 img {
  width: 100%;
}
.AwardsAchievement-2 p {
  width: 90%;
  text-align: center;
  margin: 10px auto;
  padding: 0px;
}

.contact-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 640px;
}
.contact-1 {
  width: 38%;
  background-color: #edf6ff;
  padding: 20px;
}
.contact-2 {
  flex: 1;
  padding: 0px 0px 0px 50px;
}

.contact-1-list {
  display: flex;
}
.contact-1-1 {
  width: 50px;
}
.contact-1-2 {
  flex: 1;
  padding-left: 10px;
}
.contact-1-1 img {
  width: 100%;
}
.text-field-4 {
  border-radius: 5px;
  background: #f3f3f3;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: 0px;

  padding: 20px 10px;
  width: 100%;
}
.contact-form-sec-1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}
.contact-form-sec-1 b {
  font-size: 18px;
  font-weight: 600;
  margin: 6px 0px 0px 0px;
  padding: 0px;
  color: #000b1c;
  display: block;
  text-align: left;
  width: 100% !important;
}

.contact-form-sec-1 h5 {
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px 10px 0px;
  padding: 0px;
}
.list-45 {
  width: 40% !important;
  box-sizing: border-box;
  margin: 14px 0px;
}
.react-tel-input .selected-flag {
  height: 40px !important;
  padding: 5px 10px !important;
}
.contact-2 .react-tel-input .flag-dropdown {
  height: 28px !important;
  top: 13px !important;
}

.contact-2 .react-tel-input .form-control {
  height: 25px;
}

.react-tel-input .flag-dropdown {
  padding: 0;
  background-color: #f5f5f5;
  border: 0px !important;
  height: 40px !important;
  top: 10px !important;
}

.form-control {
  width: 100% !important;
  border: 0px !important;
}
/* .selected-flag {
  padding: 4px 10px !important;
  margin: 0px;
} */
.list-100 {
  width: 100%;
  margin: 14px 0px;
}
.text-area-2 {
  border-radius: 5px;
  background: #f3f3f3;
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: 0px;
  margin: 0px 0px;
  padding: 20px 10px;
  width: 100%;
  min-height: 130px;
  resize: none;
}
.career-sec1-wrap {
  display: flex;
  width: 100%;
  margin: 50px 0px;
}
.career-sec1-1 {
  width: 40%;
}
.career-sec1-1 img {
  width: 100%;
  height: auto;
}
.career-sec1-2 {
  /* flex: 1;
  padding-left: 6%;
  margin: auto; */
  flex: inherit;
  padding-left: 0%;
  margin: auto;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.career-sec1-2 h1 {
  color: rgba(0, 0, 0, 0.97);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 150% */
  text-transform: capitalize;
}
.career-sec1-2 h1 span {
  color: #f17e00;
}

.career-sec2-wrap {
  width: 100%;
}
.career-sec2-wrap h3 {
  text-align: center;
  color: rgba(0, 0, 0, 0.97);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 150% */
  text-transform: capitalize;
}
.career-sec2-wrap h3 span {
  color: #052d71;
}
.career-btn-1 ul {
  margin: 25px 0px 40px 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.career-btn-1 ul li {
  width: 24%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8.793px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #ccc;
  position: relative;
  padding: 10px 20px;
  padding-bottom: 30px;
}
.current-openings-box {
}
.career-rm-1 {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.career-rm-1 svg {
  width: 30px;
  height: 30px;
}
.career-btn-1 h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
}

.form-sec-main {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  background-color: #eaeaea;
  padding: 30px 0px;
}

.form-sec-main h1 {
  color: rgba(30, 52, 136, 0.97);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 150% */
  text-transform: capitalize;
  margin: 0px 0px 25px 0px;
}

.form-sec-main h3 {
  color: rgba(30, 52, 136, 0.97);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 150% */
  text-transform: capitalize;
  margin: 0px 0px 25px 0px;
}

.form-sec-main form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto;
}

.form-sec-main form label {
  width: 100%;
}

.form-sec-1 {
  width: 46%;
  margin-bottom: 25px;
}
.form-sec-1 b {
  font-weight: 400;
  font-size: 12px;
  color: #595959;
}
.text-1 {
  border: 0px;
  border-bottom: 1px solid #000;
  background-color: transparent;
  border-radius: 0px;
  width: 100%;
  padding: 5px 5px;
  height: 40px;
}
.text-2 {
  border: 0px;
  border-bottom: 1px solid #000;

  border-radius: 4px;
  width: 100%;
  padding: 5px 5px;
  display: block;
  border: 1px dashed #000;

  background: #eee;
  padding: 20px;
  margin: 20px 0px;
}

.textarea-1 {
  border: 0px;
  border-bottom: 1px solid #000;
  background-color: transparent;
  border-radius: 0px;
  width: 100%;
  padding: 5px 5px;
  height: 140px;
  resize: none;
}
.text-center {
  text-align: center;
}
.submit-1 {
  padding: 8px 15px;
  background-color: #f6921e;
  color: #fff;
  font-size: 18px;
  border: 0px;
  border-radius: 5px;
  text-decoration: none;
}

.submit-1-blue {
  padding: 8px 15px;
  background-color: #00427a;
  color: #fff;
  font-size: 18px;
  border: 0px;
  border-radius: 5px;
  text-decoration: none;
}

.m-t-2 {
  margin-top: 20px !important;
  display: inline-block;
}
.career-inner-wrap {
  margin: 50px 0px;
}
.career-inner-wrap h4 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 150% */
  text-transform: uppercase;
}
.career-inner-wrap h4 span {
  color: rgba(30, 52, 136, 0.97);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.modal-overlay.active {
  opacity: 1;
}

.modal-content {
  max-width: 780px;
  width: 100%;
  position: relative;
  transform: scale(0.8);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}
.popup-content {
  border-radius: 5px;
  border: 9px solid #edf6ff;
  background: #fff;
  padding: 10px 20px;
}

.modal-overlay.active .modal-content {
  transform: scale(1);
  opacity: 1;
}

.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #364ea1;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.mc-wrap {
  display: flex;
  width: 100%;
}
.mc-wrap-1 {
  flex: 1;
  padding-right: 20px;
}
.mc-wrap-2 {
  width: 250px;
}
.mc-wrap-2 img {
  width: 100%;
  height: auto;
}

.popup-content {
  width: 100%;
  padding: 20px;
  background-color: #fff;
}
.about-rm-1 {
  margin: 10px 0px 0px 0px;
  text-align: right;
}
.about-rm-1 button {
  border: 0px;
  background-color: transparent;
  width: 50px;
}
.about-rm-1 button svg {
  width: 90%;
  height: auto;
}

.modal-content h3 {
  color: #041636;
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  margin: 0px 0px 10px 0px;
  padding: 0px;
}
.modal-content h4 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin: 0px 0px 10px 0px;
  padding: 0px;
}
.error {
  color: #ff0000;
  font-size: 11px;
}

.Products-text-wrap {
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-wrap: wrap;

  border-radius: 10px;
  margin: 50px auto;
  justify-content: space-between;
}
.product-heading-1 {
  width: 100%;
  text-align: center;
  display: block;
  max-width: 100% !important;
}
.Products-text-1 {
  width: 23%;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* margin: auto; */
  background-color: #22469d;
  padding: 20px;
  box-sizing: border-box;

  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.21);
  background-image: url(./images/Products-btn-bg.png);
  background-repeat: repeat;
  text-align: center;

  color: #fff;
  transition: 1s;
}
.Products-text-1:hover {
  transform: scale(1.03);
}
.Products-text-1 a {
  text-decoration: none;
  color: #fff;
}
.Products-text-1 h1 {
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  padding: 0px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
.Products-text-1 h4 {
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  padding: 0px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.Products-text-1 {
  font-size: 14px;
  line-height: 20px;
}
.Products-text-1-img {
  margin: 0px 0px 14px 0px;
  text-align: center;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
}
.Products-text-1-img img {
  width: 50%;
  height: auto;
}

h4.blue-3 span {
  color: #22469d !important;
}
h4.blue-3 span:after {
  background: linear-gradient(
    90deg,
    #22469d 0%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}

.mobile-map {
  display: none;
}
.filter-text-box {
  margin: 10px 0px 30px 0px;
  color: #052d71;
  border-radius: 26px;
  padding: 10px 20px;

  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25);
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: rgba(255, 255, 255, 0.75);
  display: inline-block;
}
.no-bg {
  background-color: transparent !important;
}

.mobile-product-wrap {
  padding-top: 30px;
}

.react-tel-input .form-control {
  background-color: transparent !important;
}

.form-styles.border-1 {
  border-bottom: 1px solid #000;
}

.careerform .react-tel-input .flag-dropdown {
  top: 0px !important;
  background: transparent;
}
.border-1 {
  border-bottom: 1px solid #000;
}
@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 980px) {
  .view-product-1 img {
    height: auto;
    width: auto;
    max-width: 90%;
  }
  .view-product-1 a {
    font-size: 12px;
  }
}

@media screen and (max-width: 830px) {
  .filter-button-1 {
    display: none;
  }
  .product-inner-sec1-1-bg {
    width: 100%;
    background-color: #fff;
    box-shadow: none;
    padding: 0px;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0px;
  }

  .carousel-text-1-1 {
    margin-bottom: 40px;
    text-align: center;
  }

  .banner-heading-1 h1 span {
    font-size: 16px;
  }

  .banner-heading-1 h4 span {
    font-size: 16px;
  }
  .banner-heading-1 h4 {
    margin: 6px 0px 10px 0px;
    text-align: center;
  }

  .banner-heading-1 h1 span:after {
    display: none;
  }
  .banner-heading-1 h4 span:after {
    display: none;
  }

  .filter-text-box {
    margin: 10px 0px 20px 0px;
    padding: 5px 10px;

    font-size: 14px;
  }
  .banner-section-main {
    padding: 30px 0px 80px 0px;
  }

  .certifications-1 {
    width: 31%;
  }
  .carousel-text {
    flex-wrap: wrap;
  }
  .carousel-text-1 {
    width: 100%;
  }
  .carousel-text-2 {
    width: 80%;
    margin: 0 auto;
  }
  /* .brands-bg {
    top: 0px;

    margin-bottom: 40px;
    margin-top: 40px;
  } */
  .carousel-text-2 {
    text-align: center;
  }
  .carousel-text-2 img {
    width: auto !important;
    height: 20vh !important;
  }
  .carousel-text-1 h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .brand-img-1 span {
    display: none;
  }
  .brand-img-1 {
    padding: 10px 1.5%;
  }
  .carousel-text-1-2 {
    align-self: flex-start;
  }
  .full-1 {
    text-align: right;
    margin: 0px;
  }
  .career-sec1-wrap {
    flex-wrap: wrap;
  }
  .career-sec1-1 {
    width: 100%;
  }
  .career-sec1-2 {
    flex: inherit;
    width: 100%;
    padding-left: 0%;
    margin: auto;
  }
  .career-btn-1 ul li {
    width: 100%;

    padding: 10px 10px;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
  .career-btn-1 h4 {
    font-size: 18px;
  }
  .career-sec2-wrap h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .product-dis-1 img {
    height: 250px;
  }
  .product-dis-2 h1 {
    font-size: 30px;

    font-weight: 600;
    line-height: 36px;

    width: 90%;
  }
  a.button-3,
  .button-2 {
    vertical-align: middle;
  }
}

@media screen and (max-width: 600px) {
  .contact-2 .react-tel-input .flag-dropdown {
    height: 28px !important;
    top: 3px !important;
  }
  .react-tel-input .flag-dropdown {
    top: 2px !important;
  }

  .product-dis-content {
    width: 90%;

    padding: 10px 0px;
    position: relative;

    right: 0px;
    margin-left: 5%;
    box-sizing: border-box;
    margin: 0px 5%;
  }
  .product-dis-wrap {
    padding: 0px 10px;
    flex-wrap: wrap;
    text-align: center;
  }
  .product-dis-1 {
    width: 100%;
    margin-left: -0%;
  }
  .product-dis-banner::after {
    position: absolute;
    content: "";
    width: 0%;
    background-color: transparent;
    right: 0px;
    height: 100%;
    top: 0px;
  }

  .form-sec-1 {
    width: 100%;
    margin-bottom: 14px;
  }
  .full-1 {
    text-align: right;
    margin: 0px;
  }

  .certifications-1 {
    width: 48%;
  }

  .inner-banner h1 {
    font-size: 30px;
  }

  .inner-banner {
    padding: 40px 0px;
  }
  .about-section-1-1 {
    width: 100%;
  }
  .about-section-1-2 {
    margin: auto;
    flex: inherit;
    padding-left: 0;
    padding-top: 40px;
  }
  .about-section-1-bg h1 {
    font-size: 24px;
    font-style: normal;
  }
  .about-section-2 {
    flex-wrap: wrap;
  }
  .about-section-2-1 {
    width: 100%;
  }
  .about-section-2-2-txt-1 {
    padding: 10px 20px;
    text-align: left;
  }
  .about-section-3-1 {
    width: 100%;
    margin: 10px 0px;
    border: 4px solid #edf6ff;
    padding: 20px;
  }
  .about-section-2-txt-2,
  .about-section-2-2-txt-2 {
    display: none;
  }
  .about-section-2-2 {
    width: 100%;
  }

  .carousel-text-2 img {
    width: auto !important;
    height: 30vh !important;
  }
  .carousel-text-1 h3 {
    font-size: 22px;
    margin: 10px auto 4px auto;
    line-height: 26px;
  }
  .button-1 {
    padding: 6px 36px 6px 10px;
    font-size: 14px;
  }

  .home-section-1-1 {
    width: 100%;
    padding-left: 0%;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .home-section-1-2 {
    flex: inherit;
    width: 100%;
    padding-left: 0%;
  }
  .home-section-2-wrap,
  .home-section-3-wrap {
    flex-wrap: wrap;
  }
  .home-section-2-1 {
    width: 100%;
  }
  .home-section-headoffice {
    position: relative;
    bottom: 0;
    left: 0px;
    width: 100%;
    font-size: 14px;
  }

  .heading-1 h4 span:before,
  .heading-1 h4 span:after {
    width: 80px;
    height: 3px;
  }
  .heading-1 h4 span {
    padding: 5px 10px;
  }

  .box-2-wrap {
    /* flex-wrap: nowrap; */
  }
  .home-section-3-1 {
    width: 96%;
    max-width: 100%;
  }
  .home-section-3-2 {
    width: 96%;
    margin: 0 auto;
  }
  .mobile-map {
    margin: 20px 0px 20px 0px;
    display: block;
  }
  .mobile-map img {
    width: 100%;
    height: auto;
  }
  .home-section-3-bg {
    background-image: none;
    padding: 30px 0px;
  }
  .box-2-1 {
    margin-bottom: 25px;
    /* width: 160px;
    height: 160px; */
    outline: 5px solid #fff;
    max-width: 46%;
    padding: 6px;
  }

  .box-2-1-2 p {
    /* display: none; */
    font-size: 11px;
    line-height: 14px;
  }
  .footer-bg {
    padding: 10px 5%;
    margin: 0 auto;
  }
  .home-section-1-bg h1 {
    font-size: 26px;
  }
  .heading-2 h2,
  .heading-1 h2 {
    font-size: 24px;
  }
  .heading-2 h4 {
    margin: 10px 0px 10px 0px;
  }

  .our-comitments-bg {
    background-image: none;
  }
  .QualityPromise-section-3-wrap h4 {
    font-size: 14px;

    margin: 10px 0px 10px 0px;
    line-height: 20px;
  }
  .QualityPromise-section-3-wrap svg {
    width: 50px;
    height: auto;
  }
  .em-section-1-1 {
    width: 100%;
  }
  .em-section-1-wrap h2 {
    font-size: 20px;
    line-height: 26px;
    margin: 0px 0px 14px 0px;
  }
  .em-section-1-wrap h4 {
    font-size: 18px;
  }
  .em-section-1-1 {
    order: 1;
  }
  .em-section-1-2 {
    order: 2;
    padding: 10px;
  }
  .product-inner-sec1-wrap {
    flex-wrap: wrap;
  }
  .product-inner-sec1-1 {
    width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    box-shadow: none;
    padding: 0px;
  }
  .mobile-btn-container {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    background: #3951a3;
  }
  .product-inner-sec1-2 {
    padding-left: 0%;
  }
  .view-product-1 {
    width: 48%;
    margin-bottom: 16px;
    padding: 8px;
  }
  .product-inner-sec1-2 h2 {
    font-size: 24px;
  }
  .product-inner-sec1-2 h3 {
    font-size: 14px;
  }
  .product-inner-sec1-1 button {
    width: 100%;
    margin: 2px;
    font-size: 13px;
    padding: 3px 4px;
  }
  .product-i-sec1-1 {
    max-width: 100%;
    padding: 12px;
    width: 100%;
    margin-bottom: 14px;
  }
  .product-dis-2 h1 {
    font-size: 20px;
    line-height: 24px;
    width: 100%;
  }
  .product-i-sec1-1 ul {
    width: 100%;
  }
  .button-3 {
    padding: 8px 46px 8px 20px;

    overflow: hidden;
    display: block;
    margin: 20px 0px;
    width: fit-content;
  }
  .m-l-1 {
    margin-left: 0px;
  }

  .product-dis-2 h6 {
    font-size: 14px;
    margin: 10px 0px 10px 0px;
    line-height: 16px;
  }

  .home-section-2-dec h6 {
    max-width: 280px;
  }
  .box-2-wrap {
    justify-content: space-between;
  }
  .AwardsAchievement-wrap,
  .contact-wrap {
    flex-wrap: wrap;
  }
  .AwardsAchievement-1 {
    width: 100%;
    margin-bottom: 25px;
  }
  .view-product-1 a {
    font-size: 11px;
    font-weight: 400;
  }

  .product-dis-1 img {
    width: auto;
    max-width: 50%;
    height: auto;
  }
  .product-i-sec1-1 h3 {
    font-size: 16px;
  }
  .product-i-sec1-1:last-child {
    max-width: 100%;

    min-width: 100%;
  }
  .text-field-1,
  .text-area-1 {
    padding: 6px 5px;
    font-size: 16px;

    margin-bottom: 6px;
  }
  .heading-2 h4 span {
    font-size: 16px;
  }
  .heading-2 h3 {
    font-size: 24px;

    margin: 0px;
    padding: 0px;
  }
  .contact-1 {
    width: 100%;
    padding: 10px;
  }
  .icon-2 svg {
    width: 20px;
    height: auto;
  }
  .contact-1-1 {
    width: 23px;
  }
  .contact-1-2 {
    font-size: 12px;
  }
  .contact-1-2 p {
    margin: 0px 0px 10px 0px;
  }
  .contact-2 {
    flex: inherit;
    padding: 20px 10px 10px 10px;
  }
  .list-45 {
    width: 100% !important;

    margin: 8px 0px;
  }
  .text-field-4 {
    font-size: 14px;

    padding: 10px 10px;
    width: 100%;
  }
  .inner-map iframe {
    width: 100%;
    height: 200px;
  }
  .contactbtn1 {
    background: none;
    border: none;
    color: #fff !important;
    box-shadow: none;
    width: 100%;
    text-align: left;
    margin: 0px !important;
  }
  .contactbtn1 a {
    background: none;
    border: none;
    color: #fff !important;
    box-shadow: none;
    width: 100%;
  }
  /* .header-new-logo {
    width: 100px;
    margin: auto;
  } */
  .logo {
    padding: 0px 5px;
  }
  .comitments-wrap {
    text-align: left;
  }
  .comitments-wrap h4 {
    text-align: left;
    font-size: 18px;
    line-height: 24px;
  }
  .comitments-wrap h3 {
    text-align: left;
    font-size: 24px;
  }
  .comitments-wrap ul li {
    background-size: 14px 14px;
    background-position: left top 8px;
    padding: 4px 0px 4px 24px;
    font-size: 14px;
  }
  .text-style-1 {
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  .inside-bg-2 {
    padding: 30px 0px 0px 0px;
    background-image: none;
  }
  .list-h-1 {
    background-size: 12px 12px;
    background-position: left top 6px;
    padding: 4px 0px 4px 22px;

    font-size: 16px;
    margin-top: 8px;
  }
  .comitments-wrap-2 {
    width: 96%;
    text-align: left;
    padding: 20px 0px 0px 0px;
  }
  .Products-text-1 {
    width: 100%;
    margin-bottom: 25px;
  }
  .Products-text-1-img img {
    width: auto;
    height: auto;
    max-height: 200px;
    max-width: 80%;
  }
  .mobile-product-wrap {
    margin: 20px 0px 6px 0px;
  }

  .home-section-3-1 {
    text-align: center;
    margin: 0 auto;
  }
  .home-section-3-wrap {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .box-2-1-2 p {
    font-size: 10px;
    line-height: 12px;
  }

  .brands-bg {
    /* position: relative; */
    top: -60px;

    margin-bottom: 36px;
    margin-top: -60px;
  }
  .slide img {
    width: 90% !important;
    height: auto !important;
    margin-left: 5%;
  }

  .brand-img-1 img {
    margin-right: 6px;
    height: 30px;
  }
  .brand-img-1 {
    padding: 6px 1.5%;
  }
  .button-3,
  .button-2 {
    padding: 6px 36px 6px 10px;
    margin: 4px 0px;
    font-size: 12px;
    vertical-align: middle;
  }
  a.button-3 {
    display: inline-block;
    padding: 6px 36px 6px 10px;
    margin: 4px 5px;
    font-size: 12px;
    vertical-align: middle;
  }

  .product-dis-2 h1 {
    font-size: 16px;
    line-height: 20px;
  }
  .certifications-1 {
    width: 100%;
  }
  .home-section-1-bg h1 {
    font-size: 20px;
    width: 80%;
  }
  .box-1-1-2 {
    flex: 1 1;
    padding-left: 10px;
  }
  .box-1-1-1 {
    width: 30px;
  }
  .box-1-1-1 svg {
    width: 100%;
    height: auto;
  }
  .box-1-1-2 h4 {
    font-size: 16px;
    color: #000;
  }
  .box-1-wrap {
    margin: 10px 0px;
  }
  .home-section-1-bg p {
    font-size: 13px;
    margin: 5px 0px 10px 0px;
  }
  .box-2-1-1 svg {
    width: 30px;
    height: auto;
    margin-top: 4px;
  }
  .about-section-2-2 h2,
  .about-section-2-1 h2 {
    font-size: 24px;
  }
  .about-section-2-txt-1 {
    padding: 10px 20px;
  }
  .heading-2-small h2 {
    font-size: 20px;
  }
  .QualityPromise-bg-2 {
    padding: 20px 0px 20px 0px;
  }
  .view-product-1 img {
    width: auto;
    max-width: 70%;
    height: 100px;
  }
  .view-product-1 a {
    font-size: 10px;
    font-weight: 400;
  }

  .product-i-sec1-1 ul li {
    padding: 3px 0px 3px 20px;
    font-size: 13px;
    text-align: left;
    background-size: 14px 14px;
    margin: 3px 0px;
  }
  .product-i-sec1-1 h3 {
    font-size: 15px;
  }

  .text-field-1,
  .text-area-1 {
    padding: 5px 5px;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .text-area-1 {
    min-height: 80px;
  }

  .career-inner-wrap h4 {
    font-size: 24px;

    line-height: 34px;
  }
  .career-inner-wrap {
    margin: 20px 0px;
  }
  .career-sec1-2 h1 {
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
  }
  .career-sec1-wrap {
    display: flex;
    width: 100%;
    margin: 20px 0px;
  }
  .mc-wrap {
    flex-wrap: wrap;
  }
  .mc-wrap-1 {
    width: 100%;
  }
  .mc-wrap-2 {
    width: 100%;
  }
  .popup-content {
    width: 80%;
    padding: 10px;
    background-color: #fff;
    margin: 0 auto;
  }
  .close-button {
    position: absolute;
    top: -10px;
    right: 20px;
  }
  .mc-wrap-2 img {
    display: none;
  }
  .product-i-sec1-bg {
    padding: 14px 0px;
  }
}
/* 
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 780px;
  width: 100%;
  position: relative;
} 
  .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #364ea1;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
  */

/* Initially hide the mobile-btn-container */
/* Default: Show mobile-btn-container on desktop */
.mobile-btn-container {
  display: block; /* Visible on desktop by default */
}

/* Mobile version (<= 767px) */
@media screen and (max-width: 767px) {
  /* Hide mobile-btn-container initially on mobile */
  .mobile-btn-container {
    display: none; /* Hide by default */
  }

  /* Show when toggled */
  .mobile-btn-container.show {
    display: none; /* Show when the 'show' class is added */
  }
  .AwardsAchievement-2 {
    width: 100%;
  }
}

.mobile-product-wrap {
  display: flex;
  width: 100%;
}
.mobile-product-1 {
  width: 140px;

  display: none;
}
.mobile-product-2 {
  flex: 1;
}
/* Additional styling for mobile button */
.mobile-btn {
  display: inline-block;
  padding: 3px 6px;
  background-color: #7e8cb1;
  color: #000;
  border: none;
  cursor: pointer;

  background-color: #fff;
  border: 1px solid #ccc !important;
  font-size: 13px;

  text-decoration: none;
  width: fit-content;
}
.mobile-product-wrap img {
  height: 22px;
  width: auto;
  vertical-align: middle;
  margin-right: 4px;
}

/* Styling for the mobile-btn-container */
.mobile-btn-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.mobile-btn-container button {
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .mobile-product-1 {
    display: inline-flex;
    width: 100%;
  }
  .mobile-btn-container button {
    margin-bottom: 5px;
    padding: 8px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
  }
}
