.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #ff5800 !important;
}

ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
  font-family: "Montserrat", sans-serif;
}
ul.main-menu > li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #545050;
  margin: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows {
  position: relative;
}
.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width: 767px) {
  .header__middle .active {
    color: #ff3d00 !important;
  }
}

@media (min-width: 992px) {
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus a {
  padding: 2px 0 0px 0;
  margin: 2px 14px;
}
.menu-item:hover > a {
  color: #22469d !important;
}
.menu-item .sub__menus li:hover a {
  color: #22469d !important;
}

@media (max-width: 991px) {
  .main-nav .menubar__button:hover {
    background-color: #f07f20;
  }
  .main-nav .menu-item:hover > a {
    color: #fff !important;
  }
  .main-nav .menubar__button {
    /* display: block !important;
    float: right;
    background-color: #080808;
    color: #fff;
    padding: 6px 7px;
    border-radius: 5px;
    margin: 27px auto 0;
    cursor: pointer;
    position: relative;
    z-index: 10037; */
    background-color: #080808;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: block !important;
    float: right;
    /* margin: 27px auto 0; */
    padding: 6px 7px;
    position: absolute;
    z-index: 10037;
    right: 0px;
    top: 50%;
    margin-top: -22px;
  }
  .main-nav .menubar__button svg {
    font-size: 27px;
  }
  .main-nav ul.menuq2 {
    /* display: block !important;
    position: absolute;
    left: 0;
    z-index: 10007;
    background-color: #364ea1;
    border-bottom: 3px solid #fff;
    right: 0;
    padding-bottom: 17px;
    padding-top: 57px; */
    display: block !important;
    position: fixed;
    left: 0;
    z-index: 10007;
    /* background-color: #364ea1; */
    background-image: url(../images/inner-banner.png);
    background-size: auto;
    background-repeat: repeat;
    border-bottom: 3px solid #fff;
    right: 0;
    padding-bottom: 17px;
    padding-top: 57px;
    width: 100vw;
    height: 100vh;
    left: 0px;
    height: 100vh;
    left: 0px;
    overflow-y: scroll;
    top: 0px;
  }

  .main-nav .menu-item a {
    margin: 10px 17px;
    text-align: left;
    color: #ffffff;
  }
  .menu-item .sub__menus__arrows a {
  }
  .sub__menus a {
    color: #000000 !important;
  }

  .main-nav ul.main-menu {
    display: none;
  }
  .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active {
    display: block !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active2 {
    display: block !important;
  }
  .menus__categorysss {
    display: none;
  }
  .menus__categorysss2 {
    display: none;
  }
  .menu-item .sub__menus__full a {
    text-align: left;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;

  padding: 0px;
  color: #fff;
  width: 100%;

  z-index: 99;
  top: 0px;
}
.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease; /* Optional: Add a smooth transition effect */
  /* Add other styles for your header */
}

.navbar.scrolled {
  background-color: #fafbfb; /* Change this to your desired background color */
  /* Add other styles for the scrolled state of your header */
  -webkit-box-shadow: 0px 6px 6px -5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 6px 6px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 6px 6px -5px rgba(0, 0, 0, 0.5);
  position: fixed !important;
  z-index: 999999;
}
.logo {
  /* Add your logo styles */
  width: 100%; /* Initial width */
  transition: width 0.3s ease; /* Optional: Add a smooth transition effect */
  padding: 10px 5px;
}

.logo.scrolled {
  width: 70% !important; /* Reduced width when scrolled */
}

.wrapper {
  max-width: 1170px;
  width: 96%;
  margin: 0 auto;
}
.header-new-bg {
  padding: 10px 0px;
}
.header-new-wrap {
  display: flex;
  width: 100%;
}
.header-new-logo {
  width: 160px;
  margin: auto;
}
.header-new-menu {
  flex: 1;
  margin: auto;
  position: relative;
}

.multi-talented-section {
  padding: 2px 0px;
}

@media screen and (max-width: 830px) {
  .sub__menus {
    position: relative;
  }
  .main-nav .menu-item a {
    margin: 12px 10px;
    padding: 0px;
    line-height: 26px;
    font-size: 14px !important;
  }
  .sub__menus {
    width: 100%;
    left: 0px;
    padding: 2px 0 0 0;
  }
  .sub__menus__arrows svg,
  .sub__menus__arrows__full svg {
    top: 8px;
  }
  .sub__menus {
    display: block !important;
  }
}

@media screen and (max-width: 600px) {
  .header-new-logo {
    width: 150px;
    margin: auto;
  }
}
