.carousel-inner img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.carousel-caption {
  bottom: 0px !important;
  top: 10px;
  margin: auto;
  height: 100%;
}

.banner-section-main {
  background-image: url(../images/home-banner-bg-2-n.png);
  background-size: cover;
  background-position: center;
  padding: 30px 0px 120px 0px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 830px) {
  .slide img {
    width: 88% !important;
    height: auto !important;
    margin-left: 6%;
  }
  .banner-section-main button {
    display: none;
  }
}
