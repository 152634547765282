/* Container for the tabs and content */
.tab-container {
  display: flex;
  height: 100vh;
}

.vertical-tabs {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: #f1f1f1;
}

.tab-button {
  padding: 15px;
  cursor: pointer;
  background-color: inherit;
  border: none;
  text-align: left;
  transition: 0.3s;
  font-size: 18px;
}

.tab-button:hover {
  background-color: #ddd;
}

.tab-button.active {
  background-color: #4caf50;
  color: white;
}

.tab-content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
}

.accordion {
  width: 100%;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-button {
  width: 100%;
  padding: 15px;
  cursor: pointer;
  background-color: #f1f1f1;
  border: none;
  text-align: left;
  transition: 0.3s;
  font-size: 18px;
}

.accordion-button:hover {
  background-color: #ddd;
}

.accordion-button.active {
  background-color: #4caf50;
  color: white;
}

.accordion-content {
  padding: 15px;
  background-color: #ffffff;
}

/* Thumbnail styles */
.thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.thumbnail-item {
  width: 100px;
  text-align: center;
}

.thumbnail-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

/* Responsive design for accordion mode */
@media (max-width: 768px) {
  .tab-container {
    flex-direction: column;
  }

  .vertical-tabs {
    display: none;
  }

  .accordion-item {
    display: block;
  }
}
